<template>
  <div>
    <CRow>
      <CCard v-if="ulThumbnails && ulThumbnails.length > 0" style="width: 50%">
        <CCardBody>
          <div v-for="(thumbnail, i) in ulThumbnails" :key="thumbnail.order">
            <hr />
            <CRow>
              <CCol>
                <div>
                  <img
                    v-if="thumbnail.url"
                    :src="thumbnail.url"
                    class="img-thumbnail"
                    style="max-height: 200px"
                  />
                  <div>
                    <img
                      v-if="thumbnail.blob"
                      :src="thumbnail.blob"
                      class="img-thumbnail"
                      style="max-height: 200px"
                    />
                  </div>
                </div>
              </CCol>
              <dropdown-menu
                v-model="thumbnail.show"
                :right="true"
                style="float: right; margin-right: 15px"
              >
                <CButton color="primary"
                  ><CIcon name="cil-list" size="xl"
                /></CButton>
                <div slot="dropdown">
                  <a @click="deleteImage(i)" class="dropdown-item"
                    ><CIcon name="cil-trash" size="xl" />&nbsp;&nbsp;Delete</a
                  >
                  <a v-if="i != 0" @click="moveUpImage(i)" class="dropdown-item"
                    ><CIcon name="cil-chevron-top" size="xl" />&nbsp;&nbsp;Move
                    up</a
                  >
                  <a v-if="i != ulThumbnails.length - 1" @click="moveDownImage(i)" class="dropdown-item"
                    ><CIcon class="rotate" name="cil-chevron-top" size="xl" />&nbsp;&nbsp;Move
                    down</a
                  >
                </div>
              </dropdown-menu>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CRow>
    <CCol col="2">
      <input type="file" id="file" ref="file" @input="handleFileUpload" />
    </CCol>
  </div>
</template>

<script>
import DropdownMenu from "@innologica/vue-dropdown-menu";

export default {
  name: "ImagesUploader",
  components: {
    DropdownMenu
  },
  props: ['thumbnails'],
  data: function() {
    return {
      ulThumbnails: this.thumbnails
    };
  },
  watch: {
    thumbnails: function (newVal, _oldVal) {
      this.ulThumbnails = newVal;
    },
  },
  methods: {
    handleFileUpload(event) {
      if (!this.ulThumbnails) this.ulThumbnails = [];
      if (event.target.files.length !== 0)
        this.ulThumbnails.push({
          url: null,
          order: this.ulThumbnails.length,
          blob: URL.createObjectURL(event.target.files[0]),
          file: event.target.files[0],
          show: false,
        });
      this.$refs.file.value = null;
      this.$emit("update:thumbnails", this.ulThumbnails);
    },
    deleteImage(index) {
      this.ulThumbnails.splice(index, 1);
      this.$emit("update:thumbnails", this.ulThumbnails);
    },
    moveUpImage(index) {
      if (index == 0) return;
      this.ulThumbnails[index].order--;
      this.ulThumbnails[index - 1].order++;
      this.ulThumbnails[index].show = false;
      this.ulThumbnails = this.thumbnails.sort((a, b) => a.order - b.order);
      this.$emit("update:thumbnails", this.ulThumbnails);
    },
    moveDownImage(index) {
      if (index == this.ulThumbnails.length - 1) return;
      this.ulThumbnails[index].order++;
      this.ulThumbnails[index + 1].order--;
      this.ulThumbnails[index].show = false;
      this.ulThumbnails = this.ulThumbnails.sort((a, b) => a.order - b.order);
      this.$emit("update:thumbnails", this.ulThumbnails);
    },
  },
  mounted: function () {
    let self = this;
  },
};
</script>

<style>
.dropdown-menu {
  top: auto !important;
}
.rotate {
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -moz-transform: rotate(180deg);   /* Firefox */
  -ms-transform: rotate(180deg);   /* IE 9 */
  -o-transform: rotate(180deg);   /* Opera */
  transform: rotate(180deg);
}
</style>
